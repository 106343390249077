import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "@/hooks/useUser";
import { trpc } from "@/utils/trpc";
import { usePostHog } from "posthog-js/react";
import { RenderableUserProfile } from "@/pages/api/trpc/user/renderableUserProfile";
interface UserProfileContextType {
  userProfile: RenderableUserProfile | undefined; // Replace 'any' with your actual user profile type
  isLoading: boolean;
  error: any; // Replace 'any' with your actual error type
  refetch: () => void;
}
const UserProfileContext = createContext<UserProfileContextType | undefined>(undefined);
export function UserProfileProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const user = useUser();
  const posthog = usePostHog();
  const {
    data: userProfile,
    isLoading,
    error,
    refetch
  } = trpc.users.getCurrentUserProfile.useQuery({}, {
    enabled: !!user
  });
  useEffect(() => {
    if (userProfile?.user) {
      posthog.setPersonProperties({
        isPremium: userProfile?.user?.isPremium
      });
    }
  }, [userProfile]);
  const value = {
    userProfile: userProfile?.user,
    isLoading,
    error,
    refetch
  };
  return <UserProfileContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserProfileProvider" data-sentry-source-file="useUserProfile.tsx">
      {children}
    </UserProfileContext.Provider>;
}
export function useUserProfile() {
  const context = useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error("useUserProfile must be used within a UserProfileProvider");
  }
  return context;
}