import React, { useState, useEffect, useContext, useCallback, createContext } from 'react';

// Create a context for the overlay
const OverlayContext = createContext(null);

// Styles for the component
const styles = {
  overlay: {
    zIndex: 2147483647,
    display: 'block',
    backgroundColor: 'transparent',
    border: '0px none transparent',
    // Changed from 'hidden' to 'auto' to allow scrolling
    overflow: 'auto',
    visibility: 'visible',
    margin: 0,
    padding: 0,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    // Ensure iframe allows scrolling
    overflow: 'auto'
  },
  loader: {
    zIndex: 99998,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0
  },
  loaderDot: {
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    animation: 'pulse 1s ease-out infinite forwards'
  }
};

// Add keyframes for the pulse animation
const keyframes = `
  @keyframes pulse {
    0% { opacity: 1; transform: scale(0.1); }
    100% { opacity: 0; transform: scale(1); }
  }
`;

/**
 * Provider component that maintains the overlay state
 */
export const OverlayProvider = ({
  children
}) => {
  const [overlayState, setOverlayState] = useState({
    isOpen: false,
    url: null,
    darkMode: false,
    loading: true
  });

  // Add keyframes style to document
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = keyframes;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Handle body scroll locking
  useEffect(() => {
    if (overlayState.isOpen) {
      // Store the original overflow style
      const originalStyle = window.getComputedStyle(document.body).overflow;

      // Disable scrolling on the body
      document.body.style.overflow = 'hidden';

      // Reset when unmounting or closing
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [overlayState.isOpen]);

  // Listen for messages from the iframe
  useEffect(() => {
    const handleMessage = event => {
      if (event.data === 'mounted') {
        setOverlayState(prev => ({
          ...prev,
          loading: false
        }));
      }
      if (event.data === 'close') {
        setOverlayState({
          isOpen: false,
          url: null,
          darkMode: false,
          loading: true
        });
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Open URL method
  const openUrl = useCallback((url, darkMode = false) => {
    document.body.style.pointerEvents = "auto";
    setOverlayState({
      isOpen: true,
      url,
      darkMode,
      loading: true
    });
  }, []);

  // Close overlay method
  const closeOverlay = useCallback(() => {
    setOverlayState({
      isOpen: false,
      url: null,
      darkMode: false,
      loading: true
    });
  }, []);

  // Calculate embedded URL with embed parameter
  const embeddedUrl = overlayState.url ? (() => {
    const url = new URL(overlayState.url);
    if (!url.searchParams.get('embed')) {
      url.searchParams.set('embed', '1');
    }
    return url.toString();
  })() : null;
  const value = {
    ...overlayState,
    openUrl,
    closeOverlay
  };
  return <OverlayContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="OverlayProvider" data-sentry-source-file="lemonSqueezyOverlay.tsx">
      {children}
      
      {overlayState.isOpen && <div style={styles.overlay}>
          {overlayState.loading && <div style={{
        ...styles.loader,
        backgroundColor: overlayState.darkMode ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)'
      }}>
              <div style={{
          ...styles.loaderDot,
          backgroundColor: overlayState.darkMode ? '#FFC233' : '#7047EB'
        }} />
            </div>}
          <iframe src={embeddedUrl} style={styles.iframe} allow="payment" scrolling="yes" title="Overlay Content" />
        </div>}
    </OverlayContext.Provider>;
};

/**
 * Hook to open and close the overlay
 */
export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error('useOverlay must be used within an OverlayProvider');
  }
  return {
    isOpen: context.isOpen,
    openUrl: context.openUrl,
    closeOverlay: context.closeOverlay
  };
};

// Export a component that makes it easy to include the overlay in an app
export const OverlayRoot = ({
  children
}) => <OverlayProvider data-sentry-element="OverlayProvider" data-sentry-component="OverlayRoot" data-sentry-source-file="lemonSqueezyOverlay.tsx">
    {children}
  </OverlayProvider>;
export default useOverlay;